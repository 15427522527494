import React from 'react'
import Layout from '../components/Layout/Layout'
import { Link } from 'gatsby'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const NotFoundPage = () => (
    <Layout>
        <h1>DÉSOLÉ, CETTE PAGE N'EXISTE PAS</h1>
        <Link to={'/'}>
            <ArrowBackIcon fontSize={'small'} style={{ paddingTop: 10 }} />
            Retour à la page d'acceuil
        </Link>
        <div id="bg"></div>
    </Layout>
)

export default NotFoundPage
